<template src="./customers.html"></template>

<script>
import Http from '@/shared/http-config'
import draggable from 'vuedraggable'
import StatusBox from '../components/StatusBox'
import BarcodeReturn from '../components/BarcodeReturn'
import CustomerSearch from '../components/CustomerSearch'
import moment from 'moment'
import { isMobile } from '@/shared/utils.js'
import store from '../../../Store';
import CustomerStore from '../CustomerStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect'
import {permissionCheck} from '@/shared/permission';

export default {
  name: "Customers",
  components: { draggable, StatusBox, CustomerSearch, BarcodeReturn, Multiselect },
  mixins: [RegisterStoreModule],
  data: function () {
    return {
      classes: [],
      branches: [],
      teachers: [],
      state: {
        id: 'all',
        customers: [],
        meta: {
          per_page: 15
        }
      },
      states: [],
      all_states: [],
      search_result: [],
      fields: [
        { key: 'select', label: '' },
        { key: 'customer.first_name', label: 'Vorname' },
        { key: 'customer.last_name', label: 'Nachname' },
        { key: 'class.name', label: 'Klasse' },
        { key: 'state.name', label: 'Status' },
        { key: 'customer.next_appointment.start', label: 'Offener Termin', formatter: value => { return this.$options.filters.formatDate(value, 'DD.MM.YYYY H:mm') }},
        { key: 'customer.registered_at', label: 'Erfasst am', formatter: value => { return this.$options.filters.formatDate(value) }},
      ],
      per_page_options: [
        { value: 15, text: '15' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      customer_types: {
        0: 'Anbahnung',
        1: 'Ausbildung',
        2: 'Abgeschlossen',
      },
      selected_customers: [],
      selected_state: 0,
      select_all_customers: false,
      camps: {
        fc: [],
        mc: []
      },
    }
  },
  computed: {
    schuelerGebM (){
      return moment.months().map((month, key) => {return {value: (key + 1), text: month};});
    },
    schuelerGebY (){
      return Array.from(Array(new Date().getFullYear() - 1949), (_, i) => (i + 1950).toString()).reverse();
    },
    isMobile () {
      return isMobile();
    },
    isFiltered () {
      for(let key in this.search) {
        if(key === 'klassen'){
          if(this.search[key].length > 0){
            return true;
          }
        }
        else if(this.search[key] != null){
          return true;
        }
      }
      return false;
    },
    ...mapFields('customers', {
      channels: 'channels',
      events: 'events',
      sortCol: 'sort.col',
      sortOrder: 'sort.order',
      search: 'search',
      searchKlassen: 'search.klassen',
      searchCategory: 'search.category',
      searchSchuelerGebM: 'search.schuelerGebM',
      searchSchuelerGebY: 'search.schuelerGebY',
      searchMassnahmeID: 'search.massnahmeID',
      searchJmplEventID: 'search.jmplEventID',
      searchFcCourse: 'search.fcCourseID',
      searchMcCourse: 'search.mcCourseID',
      searchLastActivity: 'search.last_activity',
      searchAppointments: 'search.appointments',
      searchAppointmentsType: 'search.appointments_type',
      searchOpenRequests: 'search.open_requests',
      searchTeamID: 'search.teamID',
      searchSeminarID: 'search.seminarID',
      searchFinishTalk: 'search.finishTalk',
      searchFilialID: 'search.filialID',
      searchFinishTheory: 'search.finishTheory',
      searchEKomiFeedback: 'search.eKomiFeedback',
      searchFeedback: 'search.feedback',
    }),
  },
  mounted: function(){
    this.$store.commit('set', ['containerFluid', true]);
    if(this.$route.hash === '#open_requests'){
      this.searchOpenRequests = true;
    }
  },
  destroyed: function(){
    this.$store.commit('set', ['containerFluid', false]);
  },
  watch: {
    $route(to, from) {
      this.states = {};
      this.getStates();
    },
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
      store.dispatch('fetchClasses'),
      store.dispatch('fetchTeachers')
    ]).finally(() => {
      next((vm) => {})
    })
  },
  created: function(){
    let self = this;
    self.registerStoreModule('customers', CustomerStore);
    self.$store.dispatch('customers/fetchChannels');
    self.$store.dispatch('customers/fetchEvents');
    self.$store.dispatch('customers/fetchStates').then(function (){
      self.branches = self.$store.getters.get('branches');
      self.classes = self.$store.getters.get('classes');
      self.teachers = self.$store.getters.get('teachers');
      self.getStates();
    });

    Http.request('GET', '/customers/camps/').then((camps) => {
      camps.data.forEach(function (camp) {
        self.camps[camp.type].push({
          value: camp.id,
          text: self.$options.filters.formatDate(camp.start) + ' - ' + self.$options.filters.formatDate(camp.end)
        });
      });
    });
  },
  methods: {
    permissionCheck,
    getStates(){
      let self = this;
      let states = {0:[],1:[],2:[]};
      let all_states = JSON.parse(JSON.stringify(self.$store.getters['customers/get']('states')));
      all_states.forEach(state => {
        states[state.is_customer].push(state);
      });
      self.all_states = JSON.parse(JSON.stringify(states));
      self.states = self.all_states;
    },
    loadCustomers(ctx, callback) {
      let self = this;
      self.getCustomers(ctx.currentPage, ctx.perPage).then(function (response){
        self.$set(self.state.meta, 'total', response.meta.total);
        callback(response.data)
      });
      return null
    },
    getCustomers (page, perPage, state) {
      let self = this;
      let params = '?page=' + page + '&per_page=' + perPage;
      params += '&sort=' + self.sortCol + '&order=' + self.sortOrder;

      for(let key in self.search) {
        if(key === 'klassen'){
          if(self.search[key].length > 0){
            for(let key2 in self.search[key]) {
              params += '&filter[klassenID][]=' + self.search[key][key2].id;
            }
          }
        }
        else if(key === 'last_activity' && self.search[key] != null){
          params += '&filter[' + key + ']=' + moment().subtract(self.search[key], "days").format('YYYY-MM-DD');
        }
        else if(self.search[key] !== null && self.search[key] !== false){
          params += '&filter[' + key + ']=' + self.search[key];
        }
      }

      return Http.request('GET', '/customers/by_education' + params).then(function (response) {
        return response;
      });
    },
    setMultiselectCustomerStatus () {
      let self = this;
      let educations = self.selected_customers.map(education => { return education.id });

      Http.request('POST', '/customers/states/' + self.selected_state + '/batch', { educations: educations }).then(function () {
        self.$refs['customers_table_all'].refresh();
        self.$bvModal.hide('multiselect_status-change');
        self.selected_customers = [];
        self.selected_state = 0;
        self.select_all_customers = false;
      });
    },
    refreshStates(){
      this.$set(this, 'states', {})
      this.getStates();
    },
    selectAllCustomers(value, status_id, customers){
      if(value){
        this.$set(this.selected_customers, customers);
      }
      else {
        this.$set(this.selected_customers, []);
      }
    },
    changeSort(sort){
      if(this.sortCol === sort && this.sortOrder === 'asc'){
        this.sortOrder = 'desc';
      }
      else if(this.sortCol === sort && this.sortOrder === 'desc'){
        this.sortCol = 'activity';
      }
      else {
        this.sortCol = sort;
        this.sortOrder = 'asc';
      }
      this.$refs['customers_table_all'].refresh();
    }
  }
}
</script>
